.input-box {

    margin-left: 3rem;

    &__title {
        font-size: 0.75rem;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 0.3rem;
    }

    &__input {
        border-radius: 0 !important;
        border: 2px solid $input-border !important;
        padding: 0.2rem 0.75rem !important;
        margin-bottom: 1rem;
    }
}