// COLOR CONFIG 
//main colors
$text-color:#263035;

//booklist/authorlist colors
$edit-icon:#EB921A;
$trash-icon:#A31D1E;
$plus-icon:#164BB1;
$item-hover:#f5f5f5;

//form colors
$form-title: #0A4C40;

$button-color:#01589D;
$button-text:#E5F8FF;
//input box colors
$input-text:#32373B;
$input-border:#959595;

//MIXINS
@mixin transition-ease {
    transition: all 0.3s ease-in-out;
}