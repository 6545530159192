.add-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;

    &__plus {
        margin-right: 0.5rem;
        color: $plus-icon;
        transform: scale(1.1);
    }
}